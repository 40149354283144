import React, { ReactElement, ReactNode } from "react";
import Head from "next/head";
import { useDispatch, useSelector } from "react-redux";
import { setModal } from "@redux/shared/actions";
import { RootState } from "@redux/reducers";
import dynamic from "next/dynamic";
import Mobile from "@components/shared/blogPost/sideWidget/mobile";
import { useDomainContext } from "@hooks/useDomainContext";
// import Header from "@components/shared/header";
import { generateGlobalVariables, minifyCSS } from "./utils";
import { Preview } from "./preview";
import CcpaPrivacyNotice from "@components/shared/legal/ccpaPrivacyNotice";

const Header = dynamic(() => import("@components/shared/header"), {
    loading: () => <p>Loading...</p>,
});
const Modal = dynamic(() => import("@components/shared/modal"));
const Footer = dynamic(() => import("@components/shared/footer"));
const CloseIcon = dynamic(() => import("@components/svg/closeIcon"));
const TermsAndConditions = dynamic(
    () => import("@components/shared/legal/termsAndConditions"),
);
const PrivacyPolicy = dynamic(
    () => import("@components/shared/legal/privacyPolicy"),
);

const availableFonts: { [key: string]: string[] } = {
    Roboto: ["Light", "Regular", "Medium", "Bold", "Black"],
    OpenSans: ["Light", "Regular", "Medium", "SemiBold", "Bold", "Black"],
    Lato: ["Light", "Regular", "Bold", "Black"],
    Poppins: ["Light", "Regular", "Medium", "SemiBold", "Bold", "Black"],
    Hind: ["Light", "Regular", "Medium", "SemiBold", "Bold"],
    Cairo: ["Light", "Regular", "Medium", "SemiBold", "Bold", "Black"],
    Oxygen: ["Light", "Regular", "Bold"],
    Montserrat: ["Light", "Regular", "Medium", "Bold", "Black"],
    Manrope: [
        "ExtraLight",
        "Light",
        "Regular",
        "Medium",
        "SemiBold",
        "Bold",
        "ExtraBold",
    ],
};

const weightsMap: { [key: string]: string } = {
    Light: "300",
    Regular: "400",
    Medium: "500",
    SemiBold: "600",
    Bold: "700",
    Black: "900",
};

export default function CommonWrapper({
    children,
}: {
    children: ReactNode;
}): ReactElement {
    const dispatch = useDispatch();

    const { domain, routeConfig } = useDomainContext();

    const handleClose = () => {
        dispatch(setModal({ active: false, content: undefined }));
    };

    const { active: modalActive, content: modalContent } = useSelector(
        (state: RootState) => state.rootReducer.shared.modal,
    );

    const getModalContent = () => {
        switch (modalContent) {
            case "privacy":
                return <PrivacyPolicy />;
            case "terms":
                return <TermsAndConditions />;
            case "sideWidget":
                return <Mobile />;
            case "ccpaPrivacyNotice":
                return <CcpaPrivacyNotice />;
            default:
                return null;
        }
    };

    const templateGlobalConfig = domain?.templateConfig;

    const metatags = routeConfig?.config?.seo?.meta;

    const fontFamily =
        typeof templateGlobalConfig?.theme === "object"
            ? (templateGlobalConfig?.theme?.fontFamily as string).replace(
                  " ",
                  "",
              )
            : "Poppins";
    const domainCustomCss =
        typeof templateGlobalConfig?.css === "string"
            ? minifyCSS(templateGlobalConfig?.css)
            : "";

    const pageCustomCss = minifyCSS(routeConfig?.config?.css ?? "");

    const globalVariables = generateGlobalVariables(templateGlobalConfig ?? {});

    const remappedThemeVariables = {
        "--primary-darken-1": "var(--global-theme-shades-darken1)",
        "--primary-darken-2": "var(--global-theme-shades-darken2)",
        "--primary-darken-3": "var(--global-theme-shades-darken3)",
        "--primary-darken-4": "var(--global-theme-shades-darken4)",
        "--primary-darken-5": "var(--global-theme-shades-darken5)",
        "--primary": "var(--global-theme-shades-primary)",
        "--primary-lighten-1": "var(--global-theme-shades-lighten1)",
        "--primary-lighten-2": "var(--global-theme-shades-lighten2)",
        "--primary-lighten-3": "var(--global-theme-shades-lighten3)",
        "--primary-lighten-4": "var(--global-theme-shades-lighten4)",
        "--primary-lighten-5": "var(--global-theme-shades-lighten5)",
    };

    return (
        <>
            <Head>
                <>
                    <style
                        dangerouslySetInnerHTML={{
                            __html: minifyCSS(
                                (
                                    availableFonts[fontFamily] ??
                                    availableFonts["Poppins"]
                                )
                                    .map(
                                        (
                                            weight: string,
                                        ): string => `@font-face {
                                    font-family: '${fontFamily}';
                                    src: url('/fonts/${fontFamily}/${fontFamily}-${weight}.woff2') format('truetype');
                                    font-weight: ${weightsMap[weight]};
                                    font-style: normal;
                                    font-display: swap;
                                }`,
                                    )
                                    .join(" "),
                            ),
                        }}
                    />
                </>
                {metatags &&
                    metatags.length &&
                    metatags.map((item, index) => (
                        <meta
                            name={item.key}
                            content={item.value}
                            key={index}
                        />
                    ))}

                {domainCustomCss ? (
                    <>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: domainCustomCss,
                            }}
                        />
                    </>
                ) : null}
                {pageCustomCss ? (
                    <>
                        <style
                            dangerouslySetInnerHTML={{
                                __html: pageCustomCss,
                            }}
                        />
                    </>
                ) : null}
            </Head>

            <div
                style={{
                    fontFamily: `'${fontFamily}', sans-serif`,
                    ...globalVariables,
                    ...remappedThemeVariables,
                }}
            >
                <Header />
                <main className="max-w-7xl mx-auto block px-4vw lg:px-4">
                    {children}
                </main>
                <Footer />
                <Preview />
                <Modal
                    onClose={handleClose}
                    active={modalActive}
                    closeIcon={<CloseIcon />}
                    backdropClosable
                    modalStyles={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight:
                            modalContent === "sideWidget" ? "80vh" : "100%",
                    }}
                    modalClassName="h-[90vh] rounded"
                    bodyStyles={{ overflowY: "scroll" }}
                    width={900}
                    header={modalTitles[modalContent ?? ""]}
                >
                    {getModalContent()}
                </Modal>
            </div>
        </>
    );
}

const modalTitles: Record<string, string> = {
    privacy: "Privacy Policy",
    terms: "Terms and Conditions",
    sideWidget: "Contents",
    ccpaPrivacyNotice: "CCPA Privacy Notice",
};
